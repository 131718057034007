<template>
    <section>
      <div data-aos="zoom-in" data-aos-once="true" data-aos-delay="100" data-aos-offset="200" data-aos-duration="400" class="max-w-7xl 3xl:max-w-8xl mx-auto mb-4 px-4 md:px-8 xl:px-0 border-b py-12 dark:border-gray-800">
        <h2 class="text-4xl font-bold text-gray-700 dark:text-gray-200 text-center md:text-center pb-14">
          {{ $t("references") }}
        </h2>
  
        <div class="grid grid-cols-12 md:gap-10 gap-y-6 lg:gap-y-0 dark:text-gray-400">
            
          <div class="col-span-12 lg:col-span-4 text-center grid place-items-center filter grayscale hover:scale-110 hover:grayscale-0 transition duration-700">
            <img src="https://www.ngsi.de/front/images/logo-sophos.png" width="150"><br>
          </div>
          <div class="col-span-12 lg:col-span-4 text-center grid place-items-center filter grayscale hover:scale-110 hover:grayscale-0 transition duration-700">
            <img src="https://www.ngsi.de/front/images/logo-microsoft.png" width="150"><br>
          </div>
          <div class="col-span-12 lg:col-span-4 text-center grid place-items-center filter grayscale hover:scale-110 hover:grayscale-0 transition duration-700">
            <img class="dark:brightness-50 dark:invert dark:opacity-60 dark:hover:brightness-200 dark:hover:invert-0 dark:hover:opacity-100" src="https://www.ngsi.de/front/images/logo-trend-micro.png" width="150"><br>
          </div>
        </div>
      </div>
    </section>
  </template>
  
